.App {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  flex: 1 1 0;
}


.Toastify__toast--success {
  background: rgba(5, 150, 105, 1) !important
}
