@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  display: flex;
  flex-direction: column;
}
body {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
}
#root {
  height: 100%;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
}
