.logo {
  font-family: "Sacramento";
  color: white;
  font-size: 4rem;
  display: inline-block;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: blur(1px);
  line-height: 1;
  margin: 30px;
  margin-left: 30px;
  margin-right: 30px;
  border-radius: 20px;
}
